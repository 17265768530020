function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const buildFontFaceSrcTemplate = fontFaceSrc => `url(${fontFaceSrc.src}) format(${fontFaceSrc.format})`;
export const buildFontFaceSrc = fontFaceSrcs => {
  if (Array.isArray(fontFaceSrcs)) {
    return fontFaceSrcs.map(buildFontFaceSrcTemplate).join(', ');
  }
  return buildFontFaceSrcTemplate(fontFaceSrcs);
};
export const constructFontFaceString = font => font ? `
        @font-face {
          font-family: ${font.fontFamily};
          src: ${buildFontFaceSrc(font.src)};
          ${font.fontStyle ? `font-style: ${font.fontStyle};` : ''}
          font-weight: ${font.fontWeight};
        }
      ` : '';
export const buildFontFaceTemplate = (additionalFont, basicFont) => {
  const specificFontFace = constructFontFaceString(additionalFont);
  const baseFontFace = basicFont ? constructFontFaceString(_objectSpread({}, additionalFont, {
    fontFamily: basicFont === null || basicFont === void 0 ? void 0 : basicFont.fontFamily
  })) : '';
  let alternativeFontFaces = [];
  if (additionalFont.alternativeFontFamilyNames) {
    alternativeFontFaces = additionalFont.alternativeFontFamilyNames.map(alternativeFontFamily => constructFontFaceString(_objectSpread({}, additionalFont, {
      fontFamily: alternativeFontFamily
    })));
  }
  return `
		${basicFont && additionalFont.fontWeight === '400' ? '' : specificFontFace}
		${baseFontFace}
    ${alternativeFontFaces.join('\n')}
	`;
};
export const setDynamicCssVars = additionalFonts => {
  if (!(additionalFonts !== null && additionalFonts !== void 0 && additionalFonts.length)) {
    return '';
  }
  const dynamicVarsString = additionalFonts.map(({
    fontWeight,
    fontFamily
  }) => `--dynamic-font-${fontWeight}: ${fontFamily}`).join(';\n');
  return `
		:root {
			${dynamicVarsString}
		}
	`;
};
export const setDynamicFontsBasedOnTenantSettings = additionalFonts => {
  if (!(additionalFonts !== null && additionalFonts !== void 0 && additionalFonts.length)) {
    return;
  }
  const style = document.createElement('style');
  const baseFont = additionalFonts.find(font => font.fontWeight === '400');
  const fontFaceString = additionalFonts.map(font => buildFontFaceTemplate(font, baseFont)).join('\n');
  const dynamicVarsString = setDynamicCssVars(additionalFonts);
  style.innerHTML = `
		${fontFaceString}

		${dynamicVarsString}
	`;
  document.head.appendChild(style);
};
const buildResetTemplate = () => `
  :root {
    --dynamic-font-300: "";
    --dynamic-font-400: "";
    --dynamic-font-500: "";
    --dynamic-font-600: "";
    --dynamic-font-700: "";
    --dynamic-font-800: "";
    --dynamic-font-900: "";
  }
`;
export const resetDynamicCssVarsToEnableFallbackFont = () => {
  const style = document.createElement('style');
  const resetTemplate = buildResetTemplate();
  style.innerHTML = `
		${resetTemplate}
	`;
  document.head.appendChild(style);
};
import NSLight from 'assets/fonts/nunito-sans/nunito-sans-light.ttf';
import NSRegular from 'assets/fonts/nunito-sans/nunito-sans-regular.ttf';
import NSSemiBold from 'assets/fonts/nunito-sans/nunito-sans-semi-bold.ttf';
import NSBold from 'assets/fonts/nunito-sans/nunito-sans-bold.ttf';
import NSExtraBold from 'assets/fonts/nunito-sans/nunito-sans-extra-bold.ttf';
import NSExtraBlack from 'assets/fonts/nunito-sans/nunito-sans-black.ttf';
import ICFRegular from 'assets/fonts/integral-cf/integral-cf-regular.ttf';
import ICFMedium from 'assets/fonts/integral-cf/integral-cf-medium.ttf';
import ICFSemiBold from 'assets/fonts/integral-cf/integral-cf-semi-bold.ttf';
import ICFBold from 'assets/fonts/integral-cf/integral-cf-bold.ttf';
import ICFExtraBold from 'assets/fonts/integral-cf/integral-cf-extra-bold.ttf';
import ICFExtraHeavy from 'assets/fonts/integral-cf/integral-cf-heavy.ttf';
import { resetDynamicCssVarsToEnableFallbackFont, setDynamicFontsBasedOnTenantSettings } from 'swag-client-common/utils/set-dynamic-fonts-based-on-tenant-settings';
const {
  additionalFonts
} = window.swag.storeSettings;
const createFontLink = href => {
  const fontLink = document.createElement('link');
  fontLink.setAttribute('as', 'font');
  fontLink.setAttribute('rel', 'preload');
  fontLink.setAttribute('type', 'font/woff2');
  fontLink.setAttribute('href', href);
  fontLink.setAttribute('crossorigin', '');
  return fontLink;
};
let getFonts = () => {
  document.head.prepend(createFontLink(NSLight));
  document.head.prepend(createFontLink(NSRegular));
  document.head.prepend(createFontLink(NSSemiBold));
  document.head.prepend(createFontLink(NSBold));
  document.head.prepend(createFontLink(NSExtraBold));
  document.head.prepend(createFontLink(NSExtraBlack));
  document.head.prepend(createFontLink(ICFRegular));
  document.head.prepend(createFontLink(ICFMedium));
  document.head.prepend(createFontLink(ICFSemiBold));
  document.head.prepend(createFontLink(ICFBold));
  document.head.prepend(createFontLink(ICFExtraBold));
  document.head.prepend(createFontLink(ICFExtraHeavy));
  resetDynamicCssVarsToEnableFallbackFont();
};
if (additionalFonts !== null && additionalFonts !== void 0 && additionalFonts.length) {
  getFonts = () => {
    setDynamicFontsBasedOnTenantSettings(additionalFonts);
  };
}
export { getFonts };
import 'swag-client-common/utils/change-public-path/change-public-path';
import { urlModifier } from 'swag-client-common/libs/url-modifier';
import { getSearchSuggestionsFailure, getSearchSuggestionsSuccess } from '../redux/search-products/search-products.actions';
import { apiCall } from './api-call';
import { additionalPrefetchCalls } from './additional-prefetch-calls';
import { getFonts } from './get-fonts';
window.swag.prefetch = Promise.all([...additionalPrefetchCalls, apiCall(urlModifier('/api/v1/category?hideEmptyCategories=true'), payload => ({
  type: 'PRODUCT_LISTING_GET_CATEGORIES_SUCCESS',
  payload
}), err => ({
  type: 'PRODUCT_LISTING_GET_CATEGORIES_FAIL',
  err
})), apiCall(urlModifier('/api/v1/get-search-suggestions'), getSearchSuggestionsSuccess, getSearchSuggestionsFailure)]);
getFonts();